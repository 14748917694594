<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM11.2451 7.43304C11.2546 7.43912 11.264 7.44517 11.2734 7.45121L15.994 10.4859C16.0026 10.4914 16.0112 10.497 16.02 10.5026C16.171 10.5996 16.3361 10.7057 16.4676 10.8083C16.6071 10.917 16.8273 11.1089 16.9571 11.4162C17.1148 11.7894 17.1148 12.2106 16.9571 12.5838C16.8273 12.8911 16.6071 13.083 16.4676 13.1917C16.3361 13.2943 16.171 13.4004 16.02 13.4974C16.0112 13.503 16.0026 13.5086 15.994 13.5141L11.2734 16.5488C11.264 16.5548 11.2545 16.5609 11.2451 16.567C11.0694 16.68 10.8846 16.7988 10.7219 16.8835C10.5582 16.9687 10.2611 17.1066 9.89314 17.0804C9.45914 17.0494 9.05999 16.8314 8.79923 16.4831C8.57813 16.1878 8.5335 15.8633 8.51664 15.6796C8.49989 15.4969 8.49995 15.2772 8.49999 15.0683C8.5 15.057 8.5 15.0458 8.5 15.0347V8.96533C8.5 8.95417 8.5 8.94296 8.49999 8.93173C8.49995 8.72279 8.49989 8.50311 8.51664 8.32042C8.5335 8.13665 8.57813 7.81219 8.79923 7.51687C9.05999 7.16856 9.45914 6.95064 9.89314 6.91964C10.2611 6.89336 10.5582 7.03127 10.7219 7.11647C10.8846 7.20117 11.0694 7.32001 11.2451 7.43304ZM10.5 9.33167V14.6683L14.6507 12L10.5 9.33167Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
