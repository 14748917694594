<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8 2C6.89543 2 6 2.89543 6 4V20C6 21.1046 6.89543 22 8 22C9.10457 22 10 21.1046 10 20V4C10 2.89543 9.10457 2 8 2Z"
      fill="#191414"
    />
    <path
      d="M16 2C14.8954 2 14 2.89543 14 4V20C14 21.1046 14.8954 22 16 22C17.1046 22 18 21.1046 18 20V4C18 2.89543 17.1046 2 16 2Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
