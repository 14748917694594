<template>
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1667 8.66671C13.5349 8.66671 13.8333 8.36823 13.8333 8.00004C13.8333 7.63185 13.5349 7.33337 13.1667 7.33337C12.7985 7.33337 12.5 7.63185 12.5 8.00004C12.5 8.36823 12.7985 8.66671 13.1667 8.66671Z"
      fill="#191414"
      stroke="#191414"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.50004 8.66671C8.86823 8.66671 9.16671 8.36823 9.16671 8.00004C9.16671 7.63185 8.86823 7.33337 8.50004 7.33337C8.13185 7.33337 7.83337 7.63185 7.83337 8.00004C7.83337 8.36823 8.13185 8.66671 8.50004 8.66671Z"
      fill="#191414"
      stroke="#191414"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.83329 8.66671C4.20148 8.66671 4.49996 8.36823 4.49996 8.00004C4.49996 7.63185 4.20148 7.33337 3.83329 7.33337C3.4651 7.33337 3.16663 7.63185 3.16663 8.00004C3.16663 8.36823 3.4651 8.66671 3.83329 8.66671Z"
      fill="#191414"
      stroke="#191414"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
