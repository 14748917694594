<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 0.25C11.5523 0.25 12 0.697715 12 1.25V3.75C12 4.30228 11.5523 4.75 11 4.75C10.4477 4.75 10 4.30228 10 3.75V1.25C10 0.697715 10.4477 0.25 11 0.25ZM3.42157 3.50157C3.8121 3.11105 4.44526 3.11105 4.83579 3.50157L6.95711 5.62289C7.34763 6.01342 7.34763 6.64658 6.95711 7.03711C6.56658 7.42763 5.93342 7.42763 5.54289 7.03711L3.42157 4.91579C3.03105 4.52526 3.03105 3.8921 3.42157 3.50157ZM18.3713 3.70868C18.7618 4.0992 18.7618 4.73237 18.3713 5.12289L16.9571 6.53711C16.5666 6.92763 15.9334 6.92763 15.5429 6.53711C15.1524 6.14658 15.1524 5.51342 15.5429 5.12289L16.9571 3.70868C17.3476 3.31816 17.9808 3.31816 18.3713 3.70868ZM0.25 11C0.25 10.4477 0.697715 10 1.25 10H4.75C5.30228 10 5.75 10.4477 5.75 11C5.75 11.5523 5.30228 12 4.75 12H1.25C0.697715 12 0.25 11.5523 0.25 11ZM17.75 11C17.75 10.4477 18.1977 10 18.75 10H20.25C20.8023 10 21.25 10.4477 21.25 11C21.25 11.5523 20.8023 12 20.25 12H18.75C18.1977 12 17.75 11.5523 17.75 11ZM7.45711 14.5429C7.84763 14.9334 7.84763 15.5666 7.45711 15.9571L4.62868 18.7855C4.23815 19.1761 3.60499 19.1761 3.21447 18.7855C2.82394 18.395 2.82394 17.7618 3.21447 17.3713L6.04289 14.5429C6.43342 14.1524 7.06658 14.1524 7.45711 14.5429ZM16.0429 16.0429C16.4334 15.6524 17.0666 15.6524 17.4571 16.0429L18.1642 16.75C18.5547 17.1405 18.5547 17.7737 18.1642 18.1642C17.7737 18.5547 17.1405 18.5547 16.75 18.1642L16.0429 17.4571C15.6524 17.0666 15.6524 16.4334 16.0429 16.0429ZM11 16C11.5523 16 12 16.4477 12 17V21C12 21.5523 11.5523 22 11 22C10.4477 22 10 21.5523 10 21V17C10 16.4477 10.4477 16 11 16Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
