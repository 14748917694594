<template>
  <svg
    width="121"
    height="18"
    viewBox="0 0 121 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.0195 0H43.3796V6.1272C44.243 4.9968 45.7324 4.2768 47.5096 4.2768C50.51 4.2768 52.6973 6.2928 52.6973 9.972V17.784H49.3372V10.4328C49.3372 8.2944 48.1572 7.1424 46.38 7.1424C44.5524 7.1424 43.3796 8.2944 43.3796 10.4328V17.784H40.0195V0Z"
      fill="#191414"
    />
    <path
      d="M67.8503 17.784H64.4614V16.0992C63.6196 17.2296 62.159 17.9496 60.4969 17.9496C57.3958 17.9496 55.1653 15.9336 55.1653 12.276V4.4712H58.5326V11.8008C58.5326 13.9392 59.7126 15.0912 61.4898 15.0912C63.3174 15.0912 64.4686 13.9392 64.4686 11.8008V4.4712H67.8575V17.784H67.8503Z"
      fill="#191414"
    />
    <path
      d="M75.801 4.2552C77.5566 4.2552 79.2331 5.0688 80.1468 6.3216V0H83.5574V17.784H80.1468V15.8112C79.3554 17.0352 77.866 18 75.7794 18C72.3905 18 69.7067 15.2352 69.7067 11.0808C69.6995 6.9192 72.3905 4.2552 75.801 4.2552ZM76.6428 7.2072C74.844 7.2072 73.1388 8.5536 73.1388 11.0736C73.1388 13.5936 74.844 15.0408 76.6428 15.0408C78.492 15.0408 80.1756 13.644 80.1756 11.124C80.1756 8.604 78.492 7.2072 76.6428 7.2072Z"
      fill="#191414"
    />
    <path
      d="M91.4864 4.2552C93.242 4.2552 94.9185 5.0688 95.8323 6.3216V0H99.2428V17.784H95.8323V15.8112C95.0408 17.0352 93.5514 18 91.4648 18C88.0759 18 85.3921 15.2352 85.3921 11.0808C85.3849 6.9192 88.0759 4.2552 91.4864 4.2552ZM92.321 7.2072C90.5222 7.2072 88.817 8.5536 88.817 11.0736C88.817 13.5936 90.5222 15.0408 92.321 15.0408C94.1702 15.0408 95.8539 13.644 95.8539 11.124C95.8539 8.604 94.1702 7.2072 92.321 7.2072Z"
      fill="#191414"
    />
    <path
      d="M101.934 0H105.294V17.784H101.934V0Z"
      fill="#191414"
    />
    <path
      d="M113.82 18C109.956 18 107.143 15.3072 107.143 11.124C107.143 6.9192 109.877 4.248 113.82 4.248C117.662 4.248 120.375 6.8688 120.375 10.836C120.375 11.268 120.353 11.7 120.281 12.132H110.554C110.719 14.1048 112.065 15.2064 113.748 15.2064C115.187 15.2064 115.979 14.4864 116.41 13.5936H120.037C119.317 16.056 117.108 18 113.82 18ZM110.575 9.8784H116.892C116.842 8.1216 115.453 7.02 113.719 7.02C112.115 7.02 110.841 8.0496 110.575 9.8784Z"
      fill="#191414"
    />
    <path
      d="M33.551 0H34.8174V0.3888H34.436V1.6776H33.9324L33.9396 0.3888H33.551V0ZM35.0836 0H35.7455L36.1485 1.08L36.5874 0H37.2062V1.6848H36.7457L36.7385 0.6264L36.3068 1.6848H35.9686L35.5369 0.5904V1.6848H35.0908V0H35.0836Z"
      fill="#B9A096"
    />
    <path
      d="M24.406 4.752V0H21.0458V3.24C22.2258 3.6288 23.3483 4.14 24.406 4.752Z"
      fill="#B9A096"
    />
    <path
      d="M22.7295 12.3408C22.6072 12.2256 22.4777 12.1176 22.3554 12.0096C22.341 12.0024 22.3338 11.988 22.3194 11.9808C22.1971 11.88 22.0747 11.7792 21.9452 11.6784C21.9236 11.664 21.9093 11.6496 21.8877 11.6352C21.7654 11.5416 21.6358 11.448 21.5063 11.3616C21.4847 11.3472 21.4703 11.3328 21.4488 11.3256C21.3193 11.2392 21.1825 11.1528 21.0458 11.0664L21.0386 11.0592H10.0876V0H6.72748V17.784H10.0876V14.4216H21.0458V17.784H24.406V14.3568C23.9311 13.6152 23.3627 12.9384 22.7295 12.3408Z"
      fill="#B9A096"
    />
    <path
      d="M27.7661 0V10.0656C24.8737 6.5232 20.4774 4.2624 15.5631 4.2624C14.2536 4.2624 12.9801 4.4208 11.7641 4.7232V8.2224C12.9585 7.8336 14.2392 7.6248 15.5631 7.6248C21.6358 7.6248 26.694 12.0096 27.7589 17.784H31.1335V0H27.7661Z"
      fill="#B9A096"
    />
    <path
      d="M3.36014 10.0656V0H0V17.784H3.37453C3.66233 16.2288 4.23795 14.7744 5.0438 13.4784V8.3016C4.43941 8.8488 3.87819 9.4392 3.36014 10.0656Z"
      fill="#B9A096"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
