<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2929 2.29295C17.788 0.797857 20.2121 0.797859 21.7072 2.29295C23.2023 3.78805 23.2023 6.21207 21.7072 7.70717L10.4274 18.9869C10.415 18.9993 10.4027 19.0116 10.3906 19.0237C10.1572 19.2573 9.98061 19.4339 9.78113 19.5887C9.60481 19.7255 9.41734 19.8474 9.2207 19.9529C8.99822 20.0723 8.76508 20.1619 8.45686 20.2804C8.44087 20.2865 8.42468 20.2928 8.40828 20.2991L8.21409 20.3738C8.21275 20.3743 8.21141 20.3748 8.21008 20.3753L2.85901 22.4334C2.49016 22.5753 2.07236 22.4866 1.79292 22.2072C1.51348 21.9277 1.42482 21.5099 1.56668 21.1411L3.70102 15.5918C3.70733 15.5754 3.71355 15.5592 3.7197 15.5432C3.83815 15.235 3.92775 15.0019 4.04718 14.7794C4.15273 14.5827 4.27456 14.3953 4.41139 14.219C4.56618 14.0195 4.74284 13.8429 4.97637 13.6095C4.98849 13.5974 5.00077 13.5851 5.0132 13.5727L16.2929 2.29295ZM5.49409 16.5012C5.49323 16.5035 5.49237 16.5057 5.49149 16.508L4.24108 19.759L7.69032 18.4324C8.06761 18.2873 8.17674 18.2434 8.27479 18.1907C8.37312 18.138 8.46685 18.077 8.55501 18.0086C8.64293 17.9404 8.72736 17.8585 9.0132 17.5727L20.2929 6.29295C21.007 5.57891 21.007 4.42121 20.2929 3.70717C19.5789 2.99312 18.4212 2.99312 17.7072 3.70716L6.42742 14.9869C6.14157 15.2727 6.05968 15.3572 5.99146 15.4451C5.92304 15.5332 5.86213 15.627 5.80935 15.7253C5.75671 15.8234 5.71282 15.9325 5.56771 16.3098L5.49409 16.5012Z"
      fill="#191414"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
