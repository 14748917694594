<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="list">
      <path
        id="Icon (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33594 3.9974C1.33594 3.26102 1.93289 2.66406 2.66927 2.66406C3.40565 2.66406 4.0026 3.26102 4.0026 3.9974C4.0026 4.73378 3.40565 5.33073 2.66927 5.33073C1.93289 5.33073 1.33594 4.73378 1.33594 3.9974ZM5.33594 3.9974C5.33594 3.62921 5.63441 3.33073 6.0026 3.33073H14.0026C14.3708 3.33073 14.6693 3.62921 14.6693 3.9974C14.6693 4.36559 14.3708 4.66406 14.0026 4.66406L6.0026 4.66406C5.63441 4.66406 5.33594 4.36559 5.33594 3.9974ZM1.33594 7.9974C1.33594 7.26102 1.93289 6.66406 2.66927 6.66406C3.40565 6.66406 4.0026 7.26102 4.0026 7.9974C4.0026 8.73378 3.40565 9.33073 2.66927 9.33073C1.93289 9.33073 1.33594 8.73378 1.33594 7.9974ZM5.33594 7.9974C5.33594 7.62921 5.63441 7.33073 6.0026 7.33073L14.0026 7.33073C14.3708 7.33073 14.6693 7.62921 14.6693 7.9974C14.6693 8.36559 14.3708 8.66406 14.0026 8.66406L6.0026 8.66406C5.63441 8.66406 5.33594 8.36559 5.33594 7.9974ZM1.33594 11.9974C1.33594 11.261 1.93289 10.6641 2.66927 10.6641C3.40565 10.6641 4.0026 11.261 4.0026 11.9974C4.0026 12.7338 3.40565 13.3307 2.66927 13.3307C1.93289 13.3307 1.33594 12.7338 1.33594 11.9974ZM5.33594 11.9974C5.33594 11.6292 5.63441 11.3307 6.0026 11.3307L14.0026 11.3307C14.3708 11.3307 14.6693 11.6292 14.6693 11.9974C14.6693 12.3656 14.3708 12.6641 14.0026 12.6641L6.0026 12.6641C5.63441 12.6641 5.33594 12.3656 5.33594 11.9974Z"
        fill="#191414"
      />
    </g>
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
