<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19.4762 9.29168C21.5079 10.4954 21.5079 13.5046 19.4762 14.7083L9.57143 20.5764C7.53968 21.7801 5 20.2754 5 17.868L5 6.13197C5 3.72457 7.53968 2.21994 9.57143 3.42364L19.4762 9.29168Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
