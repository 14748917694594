<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0208 18.707C17.4113 19.0975 18.0445 19.0975 18.435 18.707C18.8256 18.3165 18.8256 17.6833 18.435 17.2928L17.7032 16.561C18.6715 16.4228 19.5759 15.9748 20.2758 15.2756C21.1277 14.4229 21.6068 13.2665 21.6068 12.061V11.1519C21.6068 9.94648 21.1277 8.7901 20.2758 7.93828C19.4231 7.08554 18.2667 6.60645 17.0613 6.60645H13.4249C12.9231 6.60645 12.5158 7.01372 12.5158 7.51555C12.5158 8.01737 12.9231 8.42464 13.4249 8.42464H17.0613C17.7849 8.42464 18.4786 8.71192 18.9895 9.22374C19.5013 9.73466 19.7886 10.4283 19.7886 11.1519V12.061C19.7886 12.7847 19.5013 13.4783 18.9895 13.9892C18.4786 14.5011 17.7849 14.7883 17.0613 14.7883H15.9306L13.6578 12.5156H16.1524C16.6542 12.5156 17.0615 12.1083 17.0615 11.6065C17.0615 11.1046 16.6542 10.6974 16.1524 10.6974H11.8396L9.56688 8.42464H9.78859C10.2904 8.42464 10.6977 8.01737 10.6977 7.51555C10.6977 7.01372 10.2904 6.60645 9.78859 6.60645H7.74869L5.70711 4.56487C5.31658 4.17434 4.68342 4.17434 4.29289 4.56487C3.90237 4.95539 3.90237 5.58856 4.29289 5.97908L5.05476 6.74095C4.26013 6.93873 3.52647 7.34941 2.93761 7.93828C2.08578 8.7901 1.60669 9.94648 1.60669 11.1519V12.061C1.60669 13.2665 2.08578 14.4229 2.93761 15.2756C3.79035 16.1274 4.94672 16.6065 6.15219 16.6065H9.78859C10.2904 16.6065 10.6977 16.1993 10.6977 15.6974C10.6977 15.1956 10.2904 14.7883 9.78859 14.7883H6.15219C5.42854 14.7883 4.7349 14.5011 4.22399 13.9892C3.71216 13.4783 3.42489 12.7847 3.42489 12.061V11.1519C3.42489 10.4283 3.71216 9.73466 4.22399 9.22374C4.7349 8.71192 5.42854 8.42464 6.15219 8.42464H6.73846L9.01117 10.6974H7.06136C6.55954 10.6974 6.15226 11.1046 6.15226 11.6065C6.15226 12.1083 6.55954 12.5156 7.06136 12.5156H10.8294L13.1458 14.832C12.7804 14.9499 12.5158 15.2929 12.5158 15.6974C12.5158 16.1993 12.9231 16.6065 13.4249 16.6065H14.9204L17.0208 18.707Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
