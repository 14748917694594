<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.66438 2.01358C10.2092 2.10437 10.5772 2.6196 10.4864 3.16437L9.84711 6.99997H15.8195L16.5136 2.83557C16.6044 2.2908 17.1196 1.92278 17.6644 2.01358C18.2091 2.10437 18.5772 2.6196 18.4864 3.16437L17.8471 6.99997H20.5C21.0523 6.99997 21.5 7.44769 21.5 7.99997C21.5 8.55226 21.0523 8.99997 20.5 8.99997H17.5138L16.5138 15H19.5C20.0523 15 20.5 15.4477 20.5 16C20.5 16.5523 20.0523 17 19.5 17H16.1804L15.4864 21.1644C15.3956 21.7091 14.8803 22.0772 14.3356 21.9864C13.7908 21.8956 13.4228 21.3803 13.5136 20.8356L14.1528 17H8.18045L7.48638 21.1644C7.39558 21.7091 6.88036 22.0772 6.33559 21.9864C5.79082 21.8956 5.4228 21.3803 5.51359 20.8356L6.15286 17H2.5C1.94772 17 1.5 16.5523 1.5 16C1.5 15.4477 1.94772 15 2.5 15H6.48619L7.48619 8.99997H3.5C2.94772 8.99997 2.5 8.55226 2.5 7.99997C2.5 7.44769 2.94772 6.99997 3.5 6.99997H7.81953L8.51359 2.83557C8.60439 2.2908 9.11961 1.92278 9.66438 2.01358ZM9.51378 8.99997L8.51378 15H14.4862L15.4862 8.99997H9.51378Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
